import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import MuiAlert from '@mui/material/Alert';
import { addCoupon } from '../../apis/coupon';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true

function EditCoupons({ open, handleClose,editData }) {
    const queryClient = useQueryClient()
    const initialstate = {
        couponName: '',
        couponCode: '',
        isCouponPercentage: '',
        couponDiscountAmount: '',
        minimumOrderValue: '',
        maximumOrderValue: '',
        discountAfterMax: '',
        isOnlyForFirstTimer: '',
        couponValidFrom: '',
        couponValidTill: ''
    }
    const [couponFormData, setCouponFormData] = useState({})
    useEffect(() => {
        setCouponFormData({...editData})
    },[editData])
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleCouponFormData = (e) => {
        let $this = e.target
        console.log($this.type);
        let value = $this.value
        let data = {
            ...couponFormData,
            [$this.name]: $this.type === 'date' ? value : value 
        }
        setCouponFormData(data)
    }

    const handleCloseAndReset = () => {
        setIsSuccess(false)
        setIsError(false)
        setCouponFormData(initialstate)
        handleClose()
    }
    const setCouponDataToServer = useMutation((data) => addCoupon(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('couponList')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
            }
        },
        onError: () => {
            setIsError(true)
        }
    })
    const handleSubmitCouponForm = (e) => {
        e.preventDefault()
        console.log(couponFormData);
        // setCouponDataToServer.mutate(couponFormData)
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Edit Coupon</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setCouponDataToServer?.error?.message}, Please try again later</Alert>}
                    {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Coupon Updated Succesfully</Alert>}
                    <form className='' autoComplete='off' onSubmit={handleSubmitCouponForm}>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <label htmlFor="Coupon Name" className='label'>Coupon Name <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Coupon Name' value={couponFormData.couponName} onChange={handleCouponFormData} name='couponName' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Coupon Code" className='label'>Coupon Code <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Coupon Code' value={couponFormData.couponCode} onChange={handleCouponFormData} name='couponCode' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Is Coupon Percentage Based" className='label'>Is Coupon Percentage Based <small className="text-red-700">*</small>?</label>
                                <select name="isCouponPercentage" required={IS_REQUIRED} value={couponFormData.isCouponPercentage} onChange={handleCouponFormData} className='input'>
                                    <option value="">--Select Coupon Percentage Based--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="Coupon Discount Amount" className='label'>Coupon Discount Amount <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Coupon Discount Amount' value={couponFormData.couponDiscountAmount} onChange={handleCouponFormData} name='couponDiscountAmount' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Minimum Order Value" className='label'>Minimum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Minimum Order Value' value={couponFormData.minimumOrderValue} onChange={handleCouponFormData} name='minimumOrderValue' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Maximum Order Value" className='label'>Maximum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Maximum Order Value' value={couponFormData.maximumOrderValue} onChange={handleCouponFormData} name='maximumOrderValue' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Flat Discount After Maximum Order Value" className='label'>Flat Discount After Maximum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Flat Discount After Maximum Order Value' value={couponFormData.discountAfterMax} onChange={handleCouponFormData} name='discountAfterMax' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Valid Only For First Time Users" className='label'>Valid Only For First Time Users <small className="text-red-700">*</small></label>
                                <select required={IS_REQUIRED} name="isOnlyForFirstTimer" className='input' value={couponFormData.isOnlyForFirstTimer} onChange={handleCouponFormData}>
                                    <option value="">--Select Valid Only For First Time Users--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className='input-container'>
                                <label htmlFor="Coupon Valid From" className='label'>Coupon Valid From <small className="text-red-700">*</small></label>
                                <input required={IS_REQUIRED} type="date" placeholder='Enter Flat Discount After Maximum Order Value' name='couponValidFrom' value={couponFormData.couponValidFrom} onChange={handleCouponFormData} className='input' />
                            </div>
                            <div className='input-container'>
                                <label htmlFor="Coupon Valid Till" className='label'>Coupon Valid Till <small className="text-red-700">*</small></label>
                                <input required={IS_REQUIRED} type="date" placeholder='Enter Flat Discount After Maximum Order Value' name='couponValidTill' value={couponFormData.couponValidTill} onChange={handleCouponFormData} className='input' />
                            </div>
                        </div>
                        <div className='text-center my-6'>
                            {setCouponDataToServer.isLoading ? <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Save
                                </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default EditCoupons