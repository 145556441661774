import Footer from "../footer"
import Header from "../header"
import './layout.css'

function Layout({ children }) {
    return (
        <>
            <Header />
                <div className="layout">
                    <div className="container_xxl">
                        {children}
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default Layout