import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

let token = sessionStorage.getItem(TOKEN_KEY);

export const getOrdersList = async (data) => {
    // console.log(data);
    // let q = key.queryKey[1];
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios({ method: "POST", data: fd,  url: `${API_ENDPOINT}orderlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};


export const getOrderPdf = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios(
        {
            method: "post",
            url: `${API_ENDPOINT}order_view_pdf`, 
            data: fd, 
            responseType: 'blob',
            headers: { 
                Authorization: token ? `Bearer ${token}` : '', 
                'Content-Type': 'application/json' }
        }
    )
    return res
};