import React from 'react'
import { Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import { TOKEN_KEY } from '../config';
const cookies = new Cookies();


function ProtectedRoutes({ component: Component, ...restOfProps }) {
    let token = sessionStorage.getItem(TOKEN_KEY);
    let role = sessionStorage.getItem('roles');
    const { children } = restOfProps
    if (!token) {
        return <Navigate to="/login" replace />;
    } else {
        return children
    }
}

export default ProtectedRoutes
