import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUploader } from "react-drag-drop-files";
import Button from '@mui/material/Button';
import { getCategoryList } from '../../apis/category';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { editProduct } from '../../apis/product';
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr'

const fileTypes = ["JPG", "PNG", "JPEG"];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
function EditProduct({ open, handleClose, editData }) {
    const productImageUpload = useRef(null)
    const queryClient = useQueryClient()
    const initialState = {
        product_name: '',
        msds: '',
        productCategory: '',
        productDescription: '',
        productPriceDetails: [{
            SKU: '',
            Price: '',
            Stock: '',
            Code: ''
        }],
        about: [''],
        target_pest: [''],
        instructions: [''],
        ingredient: [''],
        mode_of_action: [''],
        antidote: [''],
        product_thumbnail: '',
        product_thumbnail_preview: '',
        images_of_product: [],
        images_of_product_preview: [],
        meta_title: '',
        meta_description: '',
        thumb_image_alt: '',
    }
    const [productData, setProductData] = useState(initialState)
    useEffect(() => {
        let data = {
            ...editData,
            product_thumbnail_preview: editData.product_thumbnail,
            about: JSON.parse(editData.about),
            target_pest: JSON.parse(editData.target_pest),
            instructions: JSON.parse(editData.instructions),
            ingredient: JSON.parse(editData.ingredient),
            mode_of_action: JSON.parse(editData.mode_of_action),
            antidote: JSON.parse(editData.antidote),
            productPriceDetails: JSON.parse(editData.productPriceDetails),
            images_of_product_preview: editData.images_of_product
        }
        setProductData(data)
    }, [editData])
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleAddFromData = (e, file, isFileObject, name) => {
        let data;
        if (file) {
            if (isFileObject) {
                data = {
                    ...productData,
                    [name]: e
                }
            } else {
                let fileObject = e.target.files[0]
                data = {
                    ...productData,
                    [e.target.name]: fileObject
                }
            }
        } else {
            let value = e.target.value
            data = {
                ...productData,
                [e.target.name]: value
            }
        }
        setProductData(data)
    }
    const handleAddFormFields = (type) => {
        const productArray = productData[type]
        if (type === 'productPriceDetails') {
            productArray.push({
                SKU: '',
                Price: '',
                Stock: '',
                Code: ''
            })
        } else {
            productArray.push('')
        }
        setProductData({ ...productData })
    }

    const handleDeleteFieldsFromForm = (type, idx) => {
        const productArray = productData[type]
        productArray.splice(idx, 1)
        setProductData({ ...productData })
    }
    const handleAddSubFromData = (e, type, idx) => {
        const productArray = productData[type]
        let value = e.target.value
        if (type === 'productPriceDetails') {
            productArray[idx][e.target.name] = value
        } else {
            productArray[idx] = value
        }
        setProductData({ ...productData })
    }
    const postProductDataToServer = useMutation((data) => editProduct(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('productList')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
            }
        },
        onError: () => {
            setIsError(true)
        }
    })
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleProductSubmit = (e) => {
        e.preventDefault()
        postProductDataToServer.mutate(productData)
    }
    const handleCloseAndReset = () => {
        setProductData(initialState)
        handleClose()
        closeErrorMessage()
    }
    const handleuploadAgain = () => {
        let data = {
            ...productData,
            product_thumbnail_preview: '',
            product_thumbnail: ''
        }
        setProductData(data)
    }
    const [categoryList, setCategoryList] = useState([])
    useQuery(['categoryList'], getCategoryList, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setCategoryList(data.data)
        }
    })
    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])


    const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
    const handleOpenUploadModal = () => {
        setOpenUploadImagesModal(true)
    }
    const handleCloseUploadImageModal = () => {
        setOpenUploadImagesModal(false)
    }
    const handleClickOpenFileDialog = () => {
        productImageUpload.current.click()
    }
    const handleUploadImages = (e) => {
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
            previewImag.push(URL.createObjectURL(e.target.files[i]))
        }
        let data = {
            ...productData,
            images_of_product: [...productData.images_of_product, ...e.target.files],
            images_of_product_preview: [...productData.images_of_product_preview, ...previewImag]
        }
        setProductData(data)
    }

    const handleRemoveSelectedProductImage = (e, id) => {
        e.stopPropagation();
        let predData = productData
        predData.images_of_product_preview.splice(id, 1)
        predData.images_of_product.splice(id, 1)
        setProductData({ ...productData })
    }
    return (
        <>
            <Dialog fullScreen open={open} onClose={handleCloseAndReset} TransitionComponent={Transition}>
                <div className='container_xxl'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleCloseAndReset}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Product</h1>
                        </div>
                    </div>
                    <div className='form-body py-8'>
                        <form className='' autoComplete='off' onSubmit={handleProductSubmit}>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                    <input required type="text" placeholder='Product Name' name='product_name' className='input' value={productData.product_name} onChange={handleAddFromData} />
                                </div>
                                <div>
                                    <label htmlFor="MDSF PDF" className='label'>Add MDSF PDF</label>
                                    <input type="file" name='msds' className='input' accept="application/pdf,application/vnd.ms-excel" onChange={(e) => handleAddFromData(e, true)} />
                                </div>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Select Product Category <small className="text-red-700">*</small></label>
                                    <select required name="productCategory" className='input' value={productData.productCategory} onChange={handleAddFromData}>
                                        <option value="">--Select Product Category--</option>
                                        {categoryList.map((list) => {
                                            const { id, name } = list
                                            return <option key={id} value={id}>{name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='my-4'>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Description for Product <small className="text-red-700">*</small></label>
                                    <textarea required name="productDescription" value={productData.productDescription} onChange={handleAddFromData} className="input min-h-[6rem]" placeholder="Enter description"></textarea>
                                    <small className="text-gray-600 capitalize font-semibold">Note: press enter to break the line</small>
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Product Price Details</h1>
                                    <div onClick={() => handleAddFormFields('productPriceDetails')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-7'>
                                    {productData?.productPriceDetails.map((object, idx) => {
                                        const { SKU, Price, Stock, Code } = object
                                        return <div className={`grid grid-cols-4 gap-4`} key={idx}>
                                            <div>
                                                <label htmlFor="SKU" className='label'>SKU</label>
                                                <input type="text" placeholder='SKU' name='SKU' className='input' value={SKU} onChange={(e) => handleAddSubFromData(e, 'productPriceDetails', idx)} />
                                            </div>
                                            <div>
                                                <label htmlFor="Price" className='label'>Price</label>
                                                <input type="text" placeholder='Price' name='Price' className='input' value={Price} onChange={(e) => handleAddSubFromData(e, 'productPriceDetails', idx)} />
                                            </div>
                                            <div>
                                                <label htmlFor="Stock" className='label'>Stock</label>
                                                <input type="text" placeholder='Stock' name='Stock' className='input' value={Stock} onChange={(e) => handleAddSubFromData(e, 'productPriceDetails', idx)} />
                                            </div>
                                            <div className='flex items-center justify-between divide-x'>
                                                <div>
                                                    <label htmlFor="Code" className='label'>Code</label>
                                                    <input type="text" placeholder='Code' name='Code' className='input' value={Code} onChange={(e) => handleAddSubFromData(e, 'productPriceDetails', idx)} />
                                                </div>
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('productPriceDetails', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>About Product</h1>
                                    <div onClick={() => handleAddFormFields('about')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.about.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='About' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'about', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('about', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Target Pests</h1>
                                    <div onClick={() => handleAddFormFields('target_pest')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.target_pest.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='Target Pests' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'target_pest', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('target_pest', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Usage Instructions</h1>
                                    <div onClick={() => handleAddFormFields('instructions')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.instructions.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='Usage Instructions' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'instructions', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('instructions', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Active Ingredient</h1>
                                    <div onClick={() => handleAddFormFields('ingredient')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.ingredient.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='Active Ingredient' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'ingredient', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('ingredient', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Mode of Action</h1>
                                    <div onClick={() => handleAddFormFields('mode_of_action')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.mode_of_action.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='Mode of Action' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'mode_of_action', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('mode_of_action', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Antidote</h1>
                                    <div onClick={() => handleAddFormFields('antidote')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {productData?.antidote.map((object, idx) => {
                                        return <div className='grid grid-cols-1 gap-4' key={idx}>
                                            <div className='flex'>
                                                <input type="text" placeholder='Antidote' name='product_sku' className='input' value={object} onChange={(e) => handleAddSubFromData(e, 'antidote', idx)} />
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('antidote', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                        <div className='pb-3'>
                                            <h1 className='font-semibold text-2xl'>SEO</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                        <label htmlFor="SKU" className='label'>Meta Title</label>
                                        <input type="text" placeholder='Meta Title' name='meta_title' value={productData['meta_title']} onChange={handleAddFromData} className='input' />
                                    </div>
                                    <div>
                                        <label htmlFor="Price" className='label'>Meta Description</label>
                                        <textarea placeholder='Meta Description' name='meta_description' className='input' value={productData['meta_description']} onChange={handleAddFromData}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <div>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <div className='pb-3'>
                                                <h1 className='font-semibold text-2xl'>Thumbnail of product</h1>
                                                <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className=' space-y-4'>
                                            {!productData.product_thumbnail_preview ?
                                                <FileUploader name="product_thumbnail" types={fileTypes} handleChange={(e) => handleAddFromData(e, true, true, 'product_thumbnail')} classes="file-uploader" hoverTitle='Drop here' />
                                                : <div className=' border border-dashed border-blue-700 rounded'>
                                                    <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                        <img src={productData.product_thumbnail_preview} alt="preview" />
                                                        <Button variant="outlined" onClick={handleuploadAgain}>Re-Upload</Button>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className='mt-5'>
                                            <label htmlFor="Image Alt" className='label'>Thumbnail Image alt</label>
                                            <input type="text" placeholder='Enter Thumbnail image alt' value={productData?.thumb_image_alt} onChange={handleAddFromData} name='thumb_image_alt' className='input' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                        <div className='pb-3'>
                                            <h1 className='font-semibold text-2xl'>Images of product</h1>
                                            <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center mt-12'>
                                        <Button onClick={handleOpenUploadModal} variant="contained" color='success' className='primaryBtn-contained'>Add Images</Button>
                                    </div>
                                </div>
                            </div>
                            {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{postProductDataToServer?.error?.message}, Please try again later</Alert>}
                            {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Updated Succesfully</Alert>}
                            <div className='text-center my-6'>
                                {postProductDataToServer.isLoading ?
                                    <Spinner /> :
                                    <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                        Save Product
                                    </Button>}
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
            <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
                    <div className=' max-w-5xl mx-auto bg-white'>
                        <div className=' divide-y'>
                            <div className="head-modal">
                                <div className='flex items-center space-x-4 px-6'>
                                    <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                                        <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                    </div>
                                    <h2 className='heading text-3xl'>Upload Product Images</h2>
                                </div>
                            </div>
                            <div className="body-modal py-4 px-6">
                                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative cursor-pointer'>
                                    <div className='imagePreview__container relative z-10'>
                                        <div className="grid grid-cols-4 gap-4 ">
                                            {productData.images_of_product_preview.map((img, idx) => {
                                                return <div className=' w-full h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                                                    <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                    <img src={img} alt="img" className='w-full h-full object-contain' />
                                                </div>
                                            })}
                                            <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                                                <GrAdd className=' w-16 h-16 text-gray-400' />
                                                <small>Add Product Image</small>
                                            </div>
                                        </div>
                                    </div>
                                    {productData.images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>}
                                </div>
                                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
                            </div>
                            <div className="foot-modal py-4 text-center">
                                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditProduct