import Layout from '../../components/layout'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import { useQuery, useMutation } from "@tanstack/react-query";
import { getOrderPdf, getOrdersList } from '../../apis/orders';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import Moment from 'react-moment';
import { useTableSearch } from '../../hooks/useTableSearch';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
function Orders() {
    const initialState = {
        limit: 10,
        pageNo: 0,
        phoneNumber:''
    }

    const [orderState,setOrderState] = useState(initialState)
    const [dataCount, setDataCount] = useState(0)
    const handleGetOrdersList = useQuery(['ordersList',orderState], () => getOrdersList(orderState), {
        refetchOnWindowFocus: false,
        keepPreviousData : true,
        onSuccess: (response) => {
            const {total_count} = response.data;
            setDataCount(Math.ceil(total_count / orderState.limit))
        }
    })
    const [searchVal, setSearcVal] = useState('');
    const { filteredData } = useTableSearch({
        searchVal,
        retrieve: handleGetOrdersList?.data?.data.data
    });
    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...orderState,
            pageNo: 0,
            [e.target.name]: value
        }
        setOrderState(data)
    }

    const orderReportDownload = useMutation((data) => getOrderPdf(data), {
        onSuccess: (data) => {
            console.log(data);
            let url = window.URL.createObjectURL(data.data);
            Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: url,
            }).click();
        },
        onError: () => {
        }
    })

    const handleViewOrderPdf = (id) => {
        let data = {
            id: id
        }
        orderReportDownload.mutate(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...orderState,
            pageNo: val.selected
        }
        setOrderState(data)
    }

    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Orders</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input onChange={handleSearchVal} value={orderState.phoneNumber} name='phoneNumber' type="text" placeholder='Search Order By Customer Number....' className='input' />
                            </div>
                        </div>
                        {handleGetOrdersList.isLoading ? <Spinner />
                            : handleGetOrdersList.status === "error" ? <Errors errorObject={handleGetOrdersList.error} inline /> :
                                <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Customer Phone
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Order Date
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Total Sum
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Reference No
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Order State
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    View
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData?.map((orderData, idx) => {
                                                const { order_id, total_sum, order_token, order_state, ordered_at, customer_id } = orderData
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {(orderState.limit * (orderState.pageNo + 1)) - (orderState.limit - (idx + 1))}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 text-center firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                            {customer_id}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                            <Moment date={ordered_at} format="llll" />
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                            &#8377; {total_sum}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                            {order_token}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <div className='flex items-center justify-center'>
                                                            <span className={`px-4 py-1 rounded capitalize text-sm ${order_state === '1' ? 'bg-red-200 text-red-700' : 'bg-green-200 text-green-700'}`}>
                                                                {order_state === '1' ? 'Unpaid' : 'Paid'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center space-x-5 justify-center'>
                                                            <div onClick={() => handleViewOrderPdf(order_id)}>
                                                                <IconButton>
                                                                    <RemoveRedEyeIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className=" my-7">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={dataCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName='flex items-center justify-center space-x-4'
                                    pageLinkClassName='pageNumber'
                                    previousLinkClassName='pageNumber'
                                    nextLinkClassName='pageNumber'
                                    activeLinkClassName='selectedPageNumber'
                                    disabledClassName='lastPage'
                                    disabledLinkClassName='lastPage'
                                />
                            </div>
                            </>
                                }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Orders