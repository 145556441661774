import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);



export const getDashboardData = async () => {
    // let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "GET", url: `${API_ENDPOINT}dashboard`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};